import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdTokenervice from '../../services/axios/CurdTokenervice'
import { setMessage } from './message'

export const getAssessmentData = createAsyncThunk('assessment/getAssessment', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, student_Id, term_id } = arg
    try {
        const res = await CurdTokenervice.getAssessment(token,'assessment', student_Id, term_id);
        return (res.data)
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const StudentAssessment = createSlice({
    name: 'assessment',
    initialState: { isloading: false, assessment: [], message: '' },
    reducers: {
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Assessment
        [getAssessmentData.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAssessmentData.fulfilled]: (state, action) => {
            state.isloading = false;
            state.assessment = action.payload;
            state.message = ''
        },
        [getAssessmentData.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        }
    }

})
export const { delMessage } = StudentAssessment.actions
export default StudentAssessment.reducer