import api from './api'

const create = (token, modle = "addlogin", data) => {
  return api(token).post(modle, data)
}

const get = (token, modle = "imgdata", id) => {
  return api(token).get(`${modle}/${id}`)
}

const getAll = (token, modle = "imgdata") => {
  return api(token).get(modle)
}

const getDegree = (token, modle = "degree", stdId, testKindId) => {
  return api(token).get(`${modle}/${stdId}/${testKindId}`)
}

const getinfoImage = (token, secid, classid) => {
  return api(token).get(`getListInfoById/${secid}/${classid}`)
}

const getCourses = (token, modle = "course", courseId, gredId, termId, subjectId) => {

  return api(token).get(`${modle}/${courseId}/${gredId}/${termId}/${subjectId}`)
}

const getAllTopics = (token, modle = "alltopics", gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const getTopics = (token, modle = "topics", gredId, termId, subejectId) => {

  return api(token).get(`${modle}/${gredId}/${termId}/${subejectId}`)
}

const getQuiz = (token, modle = "quiz", quizId, courseId, gredId, termId, subjectId) => {

  return api(token).get(`${modle}/${quizId}/${courseId}/${gredId}/${termId}/${subjectId}`)
}

const updateTopic = (token, modle = "topic", topicId, gredId, subejectId, termId, data) => {

  return api(token).put(`${modle}/${topicId}/${gredId}/${subejectId}/${termId}`, data)
}

const deleteTopic = (token, modle = "topic", topicId, gredId, subejectId, termId) => {

  return api(token).delete(`${modle}/${topicId}/${gredId}/${subejectId}/${termId}`)
}

const getCoursesByGrade = (token, modle = "getcoursebygrade", gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const getTopicsTitles = (token, modle = "topicstitle", gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const updateCourse = (token, modle = "course", courseId, gredId, subejectId, termId, data) => {

  return api(token).put(`${modle}/${courseId}/${gredId}/${subejectId}/${termId}`, data)
}

const updateCourseImg = (token, modle = "courseimg", courseImg, data) => {

  return api(token).put(`${modle}/${courseImg}`, data)
}

const deleteCourse = (token, modle = "course", courseId, gredId, subejectId, termId) => {

  return api(token).delete(`${modle}/${courseId}/${gredId}/${subejectId}/${termId}`)
}

const removeCourseImg = (token, imgUrl) => {
  return api(token).delete(`courseimage/${imgUrl}`)
}

const removeSubpartImg = (token, imgUrl) => {
  return api(token).delete(`subpartimage/${imgUrl}`)
}

const removeReviewImg = (token, imgUrl) => {
  
  return api(token).delete(`reviewimage/${imgUrl}`)
}

const removeQuestionImg = (token, imgUrl) => {
  
  return api(token).delete(`questionimage/${imgUrl}`)
}


const getCourseTitles = (token, modle = "coursestitle", gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const getSubpartsByGrade = (token, modle = "getsubpartbygrade", gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const updateSubpart = (token, modle = "subpart", subpartId, data) => {

  return api(token).put(`${modle}/${subpartId}`, data)
}


const deleteSubpart = (token, modle = "subpart", subpartId) => {

  return api(token).delete(`${modle}/${subpartId}`)
}

const updateSubpartImg = (token, modle = "subpartimg", subpartId, data) => {

  return api(token).put(`${modle}/${subpartId}`, data)
}


const getDtatByGrade = (token, modle, gredId, subjectId) => {

  return api(token).get(`${modle}/${gredId}/${subjectId}`)
}

const updateReviewImg = (token, modle = "reviewimg", reviewId, data) => {

  return api(token).put(`${modle}/${reviewId}`, data)
}

const delData = (token, modle , id) => {

  return api(token).delete(`${modle}/${id}`)
}

const updateData = (token, modle , id, data) => {

  return api(token).put(`${modle}/${id}`, data)
}

const delQuiz = (token,quizId,couresId,gradeId,subjectId,termId,modle="quiz") => {

  return api(token).delete(`${modle}/${quizId}/${couresId}/${gradeId}/${subjectId}/${termId}`)
}

const updateQuiz = (token,quizId,couresId,gradeId,subjectId,termId,data, modle="quiz") => {

  return api(token).put(`${modle}/${quizId}/${couresId}/${gradeId}/${subjectId}/${termId}`,data)
}

const getQuizId = (token,couresId,gradeId,subjectId,termId,modle="getquizid") => {

  return api(token).get(`${modle}/${couresId}/${gradeId}/${subjectId}/${termId}`)
}

const getQuizQuesData = (token,quizId,courseId, gredId, subjectId, termId,modle="getquestionsbyquiz") => {

  return api(token).get(`${modle}/${quizId}/${courseId}/${gredId}/${subjectId}/${termId}`)
}

const delQuestion = (token,questionId,courseId,quizId,gradeId,subjectId,termId,modle="question") => {

  return api(token).delete(`${modle}/${questionId}/${courseId}/${quizId}/${gradeId}/${subjectId}/${termId}`)
}

const updateQueson = (token,questionId,courseId,quizId,gradeId,subjectId,termId,data, modle="question") => {

  return api(token).put(`${modle}/${questionId}/${courseId}/${quizId}/${gradeId}/${subjectId}/${termId}`,data)
}

const getQuestionId = (token,courseId,quizId,gradeId,subjectId,termId,modle="questionid") => {

  return api(token).get(`${modle}/${courseId}/${quizId}/${gradeId}/${subjectId}/${termId}`)
}

const getAnswer = (token,questionId,courseId,quizId,gradeId,subjectId,termId,modle="answer") => {

  return api(token).get(`${modle}/${questionId}/${courseId}/${quizId}/${gradeId}/${subjectId}/${termId}`)
}

const getAssessment = (token, modle = "assessment", student_Id, term_id) => {
  return api(token).get(`${modle}/${student_Id}/${term_id}`)
}

const CurdTokenervice = {
  create,
  get,
  getAll,
  getDegree,
  getinfoImage,
  getCourses,
  getAllTopics,
  getTopics,
  getQuiz,
  updateTopic,
  deleteTopic,
  getCoursesByGrade,
  getTopicsTitles,
  updateCourse,
  deleteCourse,
  removeCourseImg,
  removeSubpartImg,
  removeReviewImg,
  removeQuestionImg,
  updateCourseImg,
  getCourseTitles,
  getSubpartsByGrade,
  updateSubpart,
  deleteSubpart,
  updateSubpartImg,
  updateReviewImg,
  getDtatByGrade,
  delData,
  updateData,
  delQuiz,
  updateQuiz,
  getQuizId,
  getQuizQuesData,
  delQuestion,
  updateQueson,
  getQuestionId,
  getAnswer,
  getAssessment,
}
export default CurdTokenervice
